import Services1 from "../../assets/Services1.gif";
import Services2 from "../../assets/Services2.gif";
import Services3 from "../../assets/Services3.gif";

function Services() {
  return (
    <div className="container grid grid-cols-3 gap-10 mb-10 justify-around">
      <div className="flex items-center justify-center">
        <img className="w-32" src={Services1} alt="" />
      </div>
      <div className="flex items-center justify-center">
        <img className="w-32" src={Services2} alt="" />
      </div>
      <div className="flex items-center justify-center">
        <img className="w-32" src={Services3} alt="" />
      </div>
    </div>
  );
}

export default Services;
