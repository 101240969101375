import React, { useEffect, useState } from "react";
// import SizeImage from "../../assets/Size.webp";
import { useParams } from "react-router-dom";
import {
  // useAddOrRemoveFavouriteProductMutation,
  useAddToCartMutation,
  // useGetFavouritesProductsQuery,
  useGetOneProductQuery,
} from "../../redux/Service/api";

import { Image, message } from "antd";
import Loading from "../../Components/utils/Loading";

function ProductDetails() {
  const [productCount, setProductCount] = useState(1);

  const { id } = useParams();

  const [messageApi, contextHolder] = message.useMessage();
  const info = (message: any) => {
    messageApi.open({
      type: `${message ? "error" : "success"}`,
      content: `${message ? message : "item is added"}`,

      style: {
        marginTop: "10vh",
      },
    });
  };

  const {
    data: productData,
    isError: isProdError,
    isLoading: isProdLoading,
  } = useGetOneProductQuery(id);

  const [addToCart, { isLoading: addIsLoading }] = useAddToCartMutation();

  const product = productData?.data ?? null;

  // const [addOrDeleteProduct, { isLoading: addOrDelIsLoading }] =
  //   useAddOrRemoveFavouriteProductMutation();

  // const { data: favItemsData } = useGetFavouritesProductsQuery(undefined);

  // const favItems = favItemsData?.data ?? null;

  // let handlefav = (ele: any) => {
  //   addOrDeleteProduct({ item_id: ele.id });
  // };

  const handleAddToCart = async () => {
    try {
      const res = await addToCart({
        item_id: product?.id,
        count: productCount,
      });
      info(res.data.message);

      setProductCount(1);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  // <<<<<<<<<<<<<<الصور>>>>>>>>>>>>>>>>>
  const [mainImage, setMainImage] = useState(
    "https://readymadeui.com/images/product6.webp"
  ); // الصورة الافتراضية

  useEffect(() => {
    setMainImage(product?.icon);
  }, [product?.icon]);

  // const thumbnails = [
  //   { src: "https://readymadeui.com/images/product6.webp", alt: "Product1" },
  //   { src: "https://readymadeui.com/images/product8.webp", alt: "Product2" },
  //   { src: "https://readymadeui.com/images/product5.webp", alt: "Product3" },
  //   { src: "https://readymadeui.com/images/product7.webp", alt: "Product4" },
  // ];

  // <<<<<<<<<<<<<<المقاسات>>>>>>>>>>>>>>>>>
  // const [selectedSize, setSelectedSize] = useState("SM"); // المقاس الافتراضي

  // const handleSizeClick = (size: string) => {
  //   setSelectedSize(size); // تحديث المقاس المختار
  // };

  // <<<<<<<<<<<<<<الوان>>>>>>>>>>>>>>>>>
  const [selectedColor, setSelectedColor] = useState("black"); // اللون الافتراضي

  const handleColorClick = (color: any) => {
    setSelectedColor(color?.color); // تحديث اللون المختار
    setMainImage(color?.image);
  };

  // const colors = [
  //   { color: "black", bgClass: "bg-black" },
  //   { color: "gray", bgClass: "bg-gray-400" },
  //   { color: "orange", bgClass: "bg-orange-400" },
  //   { color: "pink", bgClass: "bg-pink-500" },
  // ];

  // خاص بعرض صوره المقاسات

  const [visible, setVisible] = useState(false);
  // const [scaleStep, setScaleStep] = useState(0.5);

  return (
    <div className="container my-10">
      {contextHolder}
      <div className="p-4 lg:max-w-6xl max-w-2xl max-lg:mx-auto">
        {(isProdLoading || addIsLoading) && <Loading />}
        {isProdError && <p className="text-danger">Something went error</p>}

        <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-8 max-lg:gap-16">
          <div className="w-full lg:sticky top-0 text-center">
            <div className="lg:h-[560px]">
              <img
                src={mainImage}
                alt="Main Product"
                className="lg:w-11/12 w-full h-full rounded-md object-cover object-top"
              />
            </div>

            {product?.color_variations?.length > 0 && (
              <div className="flex flex-wrap gap-4 justify-center mx-auto mt-4">
                {product?.color_variations?.map((color: any) => (
                  <Image
                    key={color?.id}
                    src={color?.image}
                    alt="product"
                    onClick={() => setMainImage(color?.image)} // تغيير الصورة الكبيرة عند الضغط
                    className={`w-16 cursor-pointer rounded-md ${
                      mainImage === color?.image
                        ? "outline outline-gray-800"
                        : ""
                    }`}
                    style={{ width: "5rem", height: "5rem" }}
                  />
                ))}
              </div>
            )}
            {/* {product?.photos?.length > 0 && (
              <div className="flex flex-wrap gap-4 justify-center mx-auto mt-4">
                {product?.photos?.map((photo: any, index: any) => (
                  <Image
                    key={index}
                    src={photo.path}
                    alt="product"
                    // onClick={() => setMainImage(thumbnail.src)} // تغيير الصورة الكبيرة عند الضغط
                    className={`w-16 cursor-pointer rounded-md ${
                      mainImage === photo.path ? "outline outline-gray-800" : ""
                    }`}
                  />
                ))}
              </div>
            )} */}
          </div>

          <div>
            <div className="flex flex-wrap items-start gap-4">
              <div>
                <h2 className="text-2xl font-bold text-gray-800">
                  {product?.name}
                </h2>
                <p className="text-sm text-gray-500 mt-2">
                  {product?.category.name}
                </p>
              </div>

              <div className="ml-auto flex flex-wrap gap-4">
                <button
                  type="button"
                  className="px-2.5 py-1.5 bg-pink-100 text-xs text-pink-600 rounded-md flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12px"
                    fill="currentColor"
                    className="mr-1"
                    viewBox="0 0 64 64"
                  >
                    <path d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z" />
                  </svg>
                  100
                </button>
                <button
                  type="button"
                  className="px-2.5 py-1.5 bg-gray-100 text-xs text-gray-800 rounded-md flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12px"
                    fill="currentColor"
                    viewBox="0 0 512 512"
                  >
                    <path d="M453.332 85.332c0 38.293-31.039 69.336-69.332 69.336s-69.332-31.043-69.332-69.336C314.668 47.043 345.707 16 384 16s69.332 31.043 69.332 69.332zm0 0" />
                  </svg>
                </button>
              </div>
            </div>

            <hr className="my-8" />

            <div className="flex flex-wrap gap-4 items-start">
              {product?.discount ? (
                <div>
                  <p className="text-gray-800 text-4xl font-bold">
                    ${product?.discount}
                  </p>
                  <p className="text-gray-500 text-sm mt-2">
                    <s>${product?.price}</s>
                    <span className="text-sm ml-1">Tax included</span>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-4xl mt-2">
                    <span>${product?.price}</span>
                    <span className="text-sm ml-1">Tax included</span>
                  </p>
                </div>
              )}

              <div className="flex flex-wrap gap-4 ml-auto">
                <button
                  type="button"
                  className="px-2.5 py-1.5 bg-pink-100 text-xs text-pink-600 rounded-md flex items-center"
                >
                  <svg
                    className="w-3 mr-1"
                    fill="currentColor"
                    viewBox="0 0 14 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                  </svg>
                  4.8
                </button>
                <button
                  type="button"
                  className="px-2.5 py-1.5 bg-gray-100 text-xs text-gray-800 rounded-md flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 mr-1"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path d="M14.236 21.954h-3.6c-.91 0-1.65-.74-1.65-1.65v-7.201c0-.91.74-1.65 1.65-1.65h3.6a.75.75 0 0 1 .75.75v9.001a.75.75 0 0 1-.75.75zm-3.6-9.001a.15.15 0 0 0-.15.15v7.2a.15.15 0 0 0 .15.151h2.85v-7.501z" />
                  </svg>
                  87 Reviews
                </button>
              </div>
            </div>

            <hr className="my-8" />

            {/* <div>
              <div className="flex flex-nowrap justify-between items-center">
                <h3 className="text-xl font-bold text-gray-800">
                  Choose a Size
                </h3>
                <span
                  className="underline cursor-pointer"
                  onClick={() => setVisible(true)}
                >
                  Size Guide
                </span>
              </div>
              <div className="flex flex-wrap gap-4 mt-4">
                {["SM", "MD", "LG", "XL"].map((size) => (
                  <button
                    key={size}
                    type="button"
                    onClick={() => handleSizeClick(size)}
                    className={`w-10 h-10 border font-semibold text-sm rounded-md flex items-center justify-center shrink-0 
            ${
              selectedSize === size
                ? "border-gray-800" // مقاس مختار
                : "hover:border-gray-800"
            }`}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div> */}

            {/* <hr className="my-8" /> */}

            <div>
              <div className="flex flex-nowrap justify-between items-center">
                <h3 className="text-xl font-bold text-gray-800">
                  Choose a Color
                </h3>
                <span
                  className="underline cursor-pointer"
                  onClick={() => setVisible(true)}
                >
                  Size Guide
                </span>
              </div>
              <div className="flex flex-wrap gap-4 mt-4">
                {product?.color_variations?.map((color: any) => (
                  <button
                    key={color?.color}
                    type="button"
                    onClick={() => handleColorClick(color)}
                    className={`w-10 h-10 border border-white rounded-md shrink-0 hover:border-gray-800 
            ${selectedColor === color?.color ? "border-gray-800" : ""}`} // تطبيق حدّ إذا كان اللون مختارًا
                    style={{
                      backgroundColor: color?.hex_value ?? "black",
                    }}
                  />
                ))}
              </div>
            </div>

            <hr className="my-8" />

            <div className="flex gap-4">
              <button
                type="button"
                className="block w-full py-3 text-center font-semibold rounded-md border border-gray-200 hover:border-gray-800"
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
              <button
                type="button"
                className="block w-full py-3 text-center text-white font-semibold bg-gray-800 rounded-md hover:bg-gray-700"
              >
                Buy Now
              </button>
            </div>

            <div className="text-xs text-gray-500 text-center mt-4 font-medium">
              Free shipping on all orders over $50
            </div>
          </div>
        </div>
      </div>
      <Image
        width={200}
        style={{ display: "none" }}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
        preview={{
          visible,
          // scaleStep,
          src: `${product?.size_guide}`,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </div>
  );
}

export default ProductDetails;
