import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ColorPicker, Form, Input, Modal, Upload } from "antd";
import React, { useState } from "react";

function AddColorAndImage({ openColorAndIamge, setOpenColorAndIamge }) {
  const [subImages, setSubImages] = useState(null);

  const handleSubImagesChange = (fileList) => {
    setSubImages(
      fileList.length > 0
        ? fileList.slice(0, 4).map((file) => file.originFileObj)
        : null
    );
  };

  return (
    <div>
      <Modal
        title="add color and image"
        centered
        open={openColorAndIamge}
        onOk={() => setOpenColorAndIamge(false)}
        onCancel={() => setOpenColorAndIamge(false)}
        width={600}
      >
        <Form>
          <Form.Item
            name="name_en"
            label="name color"
            rules={[{ required: true, message: "Please add the English name" }]}
            className="flex-1"
          >
            <Input placeholder="name color" />
          </Form.Item>
          <Form.Item
            name="color"
            label="name color"
            rules={[{ required: true, message: "Please add the English name" }]}
            className="flex-1"
          >
            <ColorPicker defaultValue="#1677ff" showText />
          </Form.Item>
          <Form.Item
            valuePropName="fileList"
            label="Upload"
            rules={[{ required: true, message: "Please add the img" }]}
            // getValueFromEvent={normFile}
          >
            <Upload
              customRequest={({ onSuccess }) => {
                try {
                  onSuccess();
                } catch (error) {
                  console.error("Error handling file:", error);
                }
              }}
              onChange={({ fileList }) => handleSubImagesChange(fileList)}
              listType="picture"
              maxCount={1}
              multiple
            >
              <Button
                loading={false}
                icon={<FontAwesomeIcon icon={faDownload} />}
              >
                Upload Sub Images (Max: 1)
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AddColorAndImage;
