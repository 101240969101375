import { Link } from "react-router-dom";
import Social1 from "../../assets/Social1.jpg";
import Social2 from "../../assets/Social2.png";

function Footer() {
  return (
    <div className="bg-[#180337] p-8 text-white" dir="rtl">
      <div className="container grid grid-cols-2 md:grid-cols-3 gap-10">
        <div className="grid grid-cols-1 gap-5 text-md font-semibold md:text-xl">
          <div className="">
            <Link
              className="hover:text-lime-600 transition-all"
              to={"/AboutUs"}
            >
              عن الشركة
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 text-md font-semibold md:text-xl">
          <div className="">
            <Link to="/Contact" className="hover:text-lime-600 transition-all">
              تواصل معنا
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 text-md font-semibold md:text-xl">
          <div className="">
            <Link
              className="hover:text-lime-600 transition-all"
              to={"/Products"}
            >
              كل المنتجات
            </Link>
          </div>
        </div>
        <div className="col-span-3 md:col-span-3 flex flex-nowrap gap-5 justify-center items-center">
          <img className="w-16 rounded-xl" src={Social1} alt="" />
          <img className="w-16 rounded-xl" src={Social2} alt="" />
        </div>
        <div className="col-span-3 text-center font-bold text-2xl">
          <p>جميع الحقوق محفوظه</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
