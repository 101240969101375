import React, { useEffect, useState } from "react";
import { Modal, Input, message, Space, Button, Spin } from "antd";
import { useSelector } from "react-redux";

import {
  useEditUserAddressMutation,
  useGetAllUserAddressesQuery,
} from "../../redux/Service/api";

interface EditAddressProps {
  setOpenEditModal: (open: boolean) => void;
  openEditModal: boolean;
  addressIdForEdit: number | null;
  rerenderEditKey: number;
}

const EditAddress: React.FC<EditAddressProps> = ({
  setOpenEditModal,
  openEditModal,
  addressIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [build, setBuild] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [government, setGovernment] = useState("");

  // بيانات افتراضية للمستخدم
  // const userAddress = {
  //   build_name: "123",
  //   street_name: "Main St",
  //   city: "Cairo",
  //   government: "Cairo Governorate",
  // };

  const { userData } = useSelector((st: any) => st.auth);

  const { isError: isAllAddressesError, isLoading: isAllAddressesLoading } =
    useGetAllUserAddressesQuery(undefined);

  const userAddress =
    userData?.addresses?.length > 0 ? userData?.addresses[0] : null;

  useEffect(() => {
    setBuild(userAddress.build_name || "");
    setStreet(userAddress.street_name || "");
    setCity(userAddress.city || "");
    setGovernment(userAddress.government || "");
  }, [userAddress, rerenderEditKey]);

  // التعامل مع تعديل العنوان بدون API
  // const handleEditAddress = () => {
  //   if (!build || !street || !city || !government) {
  //     message.error("جميع الحقول مطلوبة");
  //     return;
  //   }

  // محاكاة نجاح تعديل العنوان
  // message.success("تم تعديل العنوان بنجاح");
  // setOpenEditModal(false);

  const [editAddress, { isLoading: isEditLoading }] =
    useEditUserAddressMutation();

  const handleEditAddress = async () => {
    if (!build) {
      message.error("Please add the build number");
      return;
    }
    if (!street) {
      message.error("Please add the street name");
      return;
    }
    if (!city) {
      message.error("Please add the city");
      return;
    }
    if (!government) {
      message.error("Please add the government");
      return;
    }

    const addressBody = {
      build_name: build,
      street_name: street,
      city: city,
      government: government,
      landmark: "landmark",
    };

    try {
      const res = await editAddress({
        id: addressIdForEdit,
        addressData: addressBody,
      });

      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("Address added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding Address:", error);
      message.error("Error adding Address. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Address"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer={null}
    >
      {isAllAddressesLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllAddressesError && (
        <p className="text-danger">Something went error</p>
      )}
      <Space direction="vertical" size="large" className="w-full">
        <div className="grid gap-4">
          <Input
            placeholder="رقم المبنى"
            value={build}
            onChange={(e) => setBuild(e.target.value)}
            className="border rounded-md p-2"
          />
          <Input
            placeholder="اسم الشارع"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            className="border rounded-md p-2"
          />
          <Input
            placeholder="اسم المدينة"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="border rounded-md p-2"
          />
          <Input
            placeholder="المحافظة"
            value={government}
            onChange={(e) => setGovernment(e.target.value)}
            className="border rounded-md p-2"
          />
        </div>
        <Button
          type="primary"
          onClick={handleEditAddress}
          loading={isEditLoading}
          className="bg-blue-500 text-white hover:bg-blue-700 w-full mt-4"
        >
          تعديل
        </Button>
      </Space>
    </Modal>
  );
};

export default EditAddress;
