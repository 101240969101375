import React, { useEffect, useState } from "react";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  DatePicker,
  Select,
  Form,
  Checkbox,
  Popconfirm,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  useAddProdPhotoMutation,
  useDeleteProdPhotoMutation,
  useEditProductMutation,
  useGetAllCategoriesQuery,
  useGetFullProductDetailsQuery,
} from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import product1 from "../../assets/Carousel1.jpg";
import EditColorAndImage from "./EditColorAndImage";

// خاص بخذف كل صوره لون للمنتج
const confirm = (e) => {
  console.log(e);
  message.success("Deleted successfully.");
};
const cancel = (e) => {
  console.log(e);
  message.error("The deletion has been cancelled.");
};

const EditProduct = ({
  setOpenEditModal,
  openEditModal,
  productIdForEdit,
  rerenderEditKey,
}) => {
  const [form] = Form.useForm();
  const [modalKey, setModalKey] = useState(0);
  const [modalKey1, setModalKey1] = useState(0);

  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [price, setPrice] = useState("");
  const [priceUsd, setPriceUsd] = useState("");
  const [priceEur, setPriceEur] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [priceUsdAfterDiscount, setPriceUsdAfterDiscount] = useState("");
  const [priceEurAfterDiscount, setPriceEurAfterDiscount] = useState("");
  const [manual, setManual] = useState("");
  const [manualEN, setManualEN] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [available, setAvailable] = useState("active"); // nonActive
  const [productionDate, setProductionDate] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isOffer, setIsOffer] = useState(false);
  const [isMostSale, setIsMostSale] = useState(false);

  const [iconImage, setIconImage] = useState(null);
  // const [subImages, setSubImages] = useState(null);

  // open and close model color and iamge
  const [openEditColorAndIamge, setopenEditColorAndIamge] = useState(false);

  const { data: allCategoriesData } = useGetAllCategoriesQuery();

  const allCategories = allCategoriesData?.data ?? null;

  const { data: productData, isLoading: isAllProductLoading } =
    useGetFullProductDetailsQuery(productIdForEdit);

  const productDetails = productData?.data ?? null;
  console.log(productDetails);
  useEffect(() => {
    if (productDetails) {
      setNameAR(productDetails.name);
      setNameEN(productDetails.name_en);
      setAvailable(productDetails.available);
      setDescription(productDetails.details);
      setDescriptionEN(productDetails.details_en);
      setManual(productDetails.manual);
      setManualEN(productDetails.manual_en);
      setPrice(productDetails.price);
      setPriceUsd(productDetails.price_dollar);
      setPriceEur(productDetails.price_eur);
      setProductionDate(dayjs(productDetails.production_date));
      setCategoryId(productDetails.category?.id);

      setIconImage([
        {
          uid: "-1",
          name: "Main-image",
          status: "done",
          url: productDetails.icon,
        },
      ]);
      // setSubImages(
      //   productDetails.photos?.map((photo) => ({
      //     uid: photo.id,
      //     name: `image-${photo.id}`,
      //     status: "done",
      //     url: photo.path,
      //   }))
      // );
      setModalKey1((prev) => prev + 11);
    }
  }, [productDetails, rerenderEditKey]);

  const [editProduct, { isLoading: isEditLoading }] = useEditProductMutation();
  const [deleteSubPhoto, { isLoading: isDelLoading }] =
    useDeleteProdPhotoMutation();
  const [addSubPhoto] = useAddProdPhotoMutation();

  const handleIconImageChange = (fileList) =>
    setIconImage(fileList.length > 0 ? fileList[0].originFileObj : null);

  // const handleSubImagesChange = (fileList) => {
  //   setSubImages(
  //     fileList.length > 0
  //       ? fileList
  //           .slice(0, 4)
  //           .map((file) => (file.originFileObj ? file.originFileObj : file))
  //       : null
  //   );
  // };

  // const handleSubImageRemoval = (removedImage) => {
  //   deleteSubPhoto(removedImage.uid);
  //   handleSubImagesChange(
  //     subImages.filter((file) => file.uid !== removedImage.uid)
  //   );
  // };

  const handleEditProduct = async () => {
    if (!iconImage) {
      message.error("Please upload a main image");
      return;
    }
    // if (!subImages) {
    //   message.error("Please upload a subImages");
    //   return;
    // }
    if (!price) {
      message.error("Please insert the price");
      return;
    }
    if (!priceUsd) {
      message.error("Please insert the price in Dollar");
      return;
    }
    if (!priceEur) {
      message.error("Please insert the price in eur");
      return;
    }
    if (!nameEN) {
      message.error("Please add the english name");
      return;
    }
    if (!nameAR) {
      message.error("Please add the arabic name");
      return;
    }
    if (!available) {
      message.error("Please select the availability of product");
      return;
    }
    if (!description) {
      message.error("Please add the description");
      return;
    }
    if (!descriptionEN) {
      message.error("Please add the english description");
      return;
    }
    if (!manual) {
      message.error("Please add the how to use");
      return;
    }
    if (!manualEN) {
      message.error("Please add the how to use in english");
      return;
    }
    if (!productionDate) {
      message.error("Please select the production Date");
      return;
    }
    if (!categoryId) {
      message.error("Please select the categoryId");
      return;
    }

    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    formData.append("available", available);
    formData.append("details", description);
    formData.append("details_en", descriptionEN);
    formData.append("manual", manual);
    formData.append("manual_en", manualEN);
    formData.append("production_date", productionDate);
    formData.append("category_id", categoryId);
    formData.append("price", price);
    formData.append("price_dollar", priceUsd);
    formData.append("price_eur", priceEur);
    // Object.entries(values)?.forEach(([key, value]) =>
    //   formData.append(key, value)
    // );

    if (typeof iconImage === "object" && !Array.isArray(iconImage)) {
      formData.append("icon", iconImage);
    }
    // subImages?.forEach(
    //   (file, index) => file.size && formData.append(`photos[${index}]`, file)
    // );

    // if (typeof iconImage === "object" && !Array.isArray(iconImage)) {
    //   formData.append("icon", iconImage);
    // }
    // const newPhotosFormData = new FormData();
    // subImages?.forEach((file, index) => {
    //   if (file.size) {
    //     newPhotosFormData.append(`photos[${index}]`, file);
    //   }
    // });

    try {
      const res = await editProduct({ id: productIdForEdit, formData });
      // const res2 = await addSubPhoto({
      //   id: productIdForEdit,
      //   newPhotosFormData,
      // });
      setIconImage(null);
      // setSubImages(null);
      setNameAR("");
      setNameEN("");
      setPrice("");
      setPriceUsd("");
      setPriceEur("");
      setAvailable("active");
      setCategoryId("");
      setDescription("");
      setDescriptionEN("");
      setManual("");
      setManualEN("");
      setProductionDate("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Product updating successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error updating product:", error);
      message.error("Error updating product. Please try again.");
    }
  };

  const dateFormat = "YYYY-MM-DD";
  const defaultDate = productionDate ? dayjs(productionDate, dateFormat) : null;
  return (
    <>
      <Modal
        key={modalKey}
        title="Edit Product"
        // centered
        open={openEditModal}
        onCancel={() => setOpenEditModal(false)}
        width={800}
        footer
        className="top-5"
      >
        <Form form={form} layout="vertical" onFinish={handleEditProduct}>
          <Space direction="vertical" className="w-full space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item name="name" label="اسم المنتج" className="flex-1">
                <Input
                  placeholder="اسم المنتج"
                  className="text-right"
                  value={nameAR}
                  onChange={(e) => setNameAR(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="name_en" label="Product Name (EN)">
                <Input
                  placeholder="name in English"
                  value={nameEN}
                  onChange={(e) => setNameEN(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item name="manual" label="How to Use (AR)">
                <TextArea
                  placeholder="كيفية الاستخدام"
                  className="rtl text-right"
                  value={manual}
                  onChange={(e) => setManual(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="manual_en" label="How to Use (EN)">
                <TextArea
                  placeholder="How to use in English"
                  className="text-left"
                  value={manualEN}
                  onChange={(e) => setManualEN(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item
                className="flex-1"
                name="details"
                label="Product Details (AR)"
              >
                <TextArea
                  placeholder="تفاصيل المنتج"
                  className="rtl text-right"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                className="flex-1"
                name="details_en"
                label="Product Details (EN)"
              >
                <TextArea
                  placeholder="Product details in English"
                  className="text-left"
                  value={descriptionEN}
                  onChange={(e) => setDescriptionEN(e.target.value)}
                />
              </Form.Item>
            </div>
            {/* new */}

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <Form.Item
                name="price"
                label="Price(UAE)"
                rules={[{ required: true, message: "Please add the price" }]}
                className="flex-1"
              >
                <Input
                  type="number"
                  placeholder="السعر بالدرهم"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="price_dollar"
                label="Price (USD)"
                rules={[
                  { required: true, message: "Please add the price in USD" },
                ]}
                className="flex-1"
              >
                <Input
                  type="number"
                  placeholder="السعر بالدولار"
                  value={priceUsd}
                  onChange={(e) => setPriceUsd(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name="price_eur"
                label="Price (EUR)"
                rules={[
                  { required: true, message: "Please add the price in EUR" },
                ]}
                className="flex-1"
              >
                <Input
                  type="number"
                  placeholder="السعر باليورو"
                  value={priceEur}
                  onChange={(e) => setPriceEur(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <Form.Item
                name="price_discount"
                label="Price(UAE) discount"
                rules={[{ required: true, message: "Please add the price" }]}
                className="flex-1"
              >
                <Input type="number" placeholder="السعر بالدرهم" />
              </Form.Item>
              <Form.Item
                name="price_dollar_discount"
                label="Price (USD) discount"
                rules={[
                  { required: true, message: "Please add the price in USD" },
                ]}
                className="flex-1"
              >
                <Input type="number" placeholder="السعر بالدولار" />
              </Form.Item>

              <Form.Item
                name="price_eur_discount"
                label="Price (EUR) discount"
                rules={[
                  { required: true, message: "Please add the price in EUR" },
                ]}
                className="flex-1"
              >
                <Input type="number" placeholder="السعر باليورو" />
              </Form.Item>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <Form.Item name="category_id" label="Category">
                <Select
                  key={modalKey1}
                  placeholder="Select Category"
                  onChange={(e) => setCategoryId(e)}
                  defaultValue={categoryId}
                  options={allCategories?.map((category) => ({
                    value: category.id,
                    label: category.name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="available"
                label="Availability"
                initialValue="active"
              >
                <Select
                  key={modalKey1}
                  placeholder="Availability"
                  onChange={(e) => setAvailable(e)}
                  defaultValue={available}
                  options={[
                    { value: "active", label: "Available" },
                    { value: "nonActive", label: "Not Available" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="production_date" label="Production Date">
                <DatePicker
                  placeholder="Production Date"
                  key={modalKey1}
                  defaultValue={defaultDate}
                  onChange={(dateObj, dateString) => {
                    setProductionDate(dateString);
                  }}
                />
              </Form.Item>
              <Form.Item label=".">
                <Checkbox>Add to best seller</Checkbox>
              </Form.Item>
              <Form.Item label=".">
                <Checkbox>Add to offers</Checkbox>
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Upload
                key={modalKey1}
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleIconImageChange(fileList);
                }}
                listType="picture"
                maxCount={1}
                defaultFileList={iconImage}
              >
                <Button
                  loading={isAllProductLoading}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Main Image (Max: 1)
                </Button>
              </Upload>
              <div className="">
                <Button onClick={() => setopenEditColorAndIamge(true)}>
                  add color and image
                </Button>
                <div className="grid grid-cols-1 gap-3 mt-3">
                  <div className="grid grid-cols-3 items-center gap-5 border-2 p-2 rounded-md">
                    <img className="rounded-md" src={product1} alt="product1" />
                    <div className="grid items-center justify-center">
                      <div className="bg-slate-700 w-8 h-8 rounded-md"></div>
                    </div>
                    <div className="grid grid-cols-1 gap-2">
                      <Button className="w-full" type="primary">
                        Update{" "}
                      </Button>
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure you want to delete this color and image?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button danger>Delete</Button>
                      </Popconfirm>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-center gap-5 border-2 p-2 rounded-md">
                    <img className="rounded-md" src={product1} alt="product1" />
                    <div className="grid items-center justify-center">
                      <div className="bg-slate-700 w-8 h-8 rounded-md"></div>
                    </div>
                    <div className="grid grid-cols-1 gap-2">
                      <Button className="w-full" type="primary">
                        Update{" "}
                      </Button>
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure you want to delete this color and image?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button danger>Delete</Button>
                      </Popconfirm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isEditLoading || isAllProductLoading}
              >
                Update Product
              </Button>
            </Form.Item>
          </Space>
        </Form>
        <EditColorAndImage
          openEditColorAndIamge={openEditColorAndIamge}
          setopenEditColorAndIamge={setopenEditColorAndIamge}
        />
      </Modal>
    </>
  );
};

export default EditProduct;
