import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../Components/RootLayout/RootLayout";

import { ErrorPage } from "./ErrorPage";

import Home from "../Page/Home";
import ProductDetails from "../Page/ProductDetails";
import Cart from "../Page/Cart";
import Contact from "../Page/Contact";
import Login from "../Page/Login";
import Registration from "../Page/Registration";
import Dashboard from "../Dashboard";
// import HomeDashboard from "../Dashboard/HomeDashboard";
import UsersControl from "../Dashboard/UsersControl/UsersControl";
import ProductsControl from "../Dashboard/ProductsControl/ProductsControl";
import OrdersControl from "../Dashboard/OrdersControl/OrdersControl";
import AboutUs from "../Page/AboutUs";
import ProfileUser from "../Page/ProfileUser";
import MyOrder from "../Page/MyOrder";
import Favourite from "../Page/Favourite";
import Categories from "../Dashboard/Categories/Categories";
import AddSlider from "../Dashboard/AddSlider/AddSlider";

import store from "../redux/store";
import Products from "../Page/Products";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "ProductDetails/:id", element: <ProductDetails /> },
      { path: "Cart", element: <Cart /> },
      { path: "Contact", element: <Contact /> },
      { path: "AboutUs", element: <AboutUs /> },
      { path: "ProfileUser", element: <ProfileUser /> },
      { path: "MyOrder", element: <MyOrder /> },
      { path: "Favourite", element: <Favourite /> },
      { path: "Products", element: <Products /> },
      { path: "Category/:id", element: <Products /> },
    ],
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    // loader: (e) => {
    //   if (store.getState().auth.userData?.role !== "admin") {
    //     window.location.href = "/";
    //   }
    //   return null;
    // },
    errorElement: <ErrorPage />,
    children: [
      // { index: true, element: <HomeDashboard /> },
      { index: true, element: <UsersControl /> },
      { path: "users", element: <UsersControl /> },
      { path: "category", element: <Categories /> },
      // { path: "brands", element: <Brands /> },
      { path: "products", element: <ProductsControl /> },
      { path: "orders", element: <OrdersControl /> },
      // { path: "articles", element: <ArticlesControl /> },
      // { path: "tips", element: <TipsControl /> },
      // { path: "specialOffers", element: <SpecialOffers /> },
      { path: "AddSlider", element: <AddSlider /> },
    ],
  },
  { path: "Login", element: <Login /> },
  { path: "signUp", element: <Registration /> },
]);
