import Categories1 from "../../assets/Categories1.jpg";
import Categories2 from "../../assets/Categories2.jpg";
import Row from "../../assets/row.gif";
import Loading from "../../Components/utils/Loading";
import { useGetAllCategoriesQuery } from "../../redux/Service/api";
import { language } from "../../language/Language";
import { useNavigate } from "react-router-dom";
// import AOS from "aos";
// AOS.init();

function Categories() {
  const route = useNavigate();

  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
  } = useGetAllCategoriesQuery(undefined);
  const allCategories = allCategoriesData?.data ?? null;

  return (
    <div
      // data-aos={"fade-up"}
      className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"
    >
      {isCatLoading && <Loading />}
      {isCatError && (
        <p>{language === "ar" ? "حدث خطأ ما" : "Something went error"}</p>
      )}
      {allCategories?.map((category: any) => (
        <div
          className="h-[850px]"
          key={category.id}
          onClick={() => route(`/Category/${category.id}`)}
        >
          <img
            className="w-full h-[90%] rounded-bl-[80px] rounded-tr-[80px]"
            alt={category.name}
            src={category.photo}
          />
          <div className="flex flex-nowrap items-center justify-center">
            <h2 className="font-bold text-2xl lg:text-4xl">{category.name}</h2>
            <img className="w-16" src={Row} alt="" />
          </div>
        </div>
      ))}

      {/* <div className="h-[850px]">
        <img
          className="w-full h-[90%] rounded-bl-[80px] rounded-tr-[80px]"
          src={Categories2}
          alt=""
        />
        <div className="flex flex-nowrap items-center justify-center">
          <h2 className="font-bold text-2xl lg:text-4xl"> ملابس حريمى </h2>
          <img className="w-16" src={Row} alt="" />
        </div>
      </div>
      <div className="h-[850px]">
        <img
          className="w-full h-[90%] rounded-bl-[80px] rounded-tr-[80px]"
          src={Categories1}
          alt=""
        />
        <div className="flex flex-nowrap items-center justify-center">
          <h2 className="font-bold text-2xl lg:text-4xl">ملابس رجالى </h2>
          <img className="w-16" src={Row} alt="" />
        </div>
      </div> */}
    </div>
  );
}

export default Categories;
