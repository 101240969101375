import { DownOutlined } from "@ant-design/icons";
import {
  faArrowRightToBracket,
  faBabyCarriage,
  faCartShopping,
  faHeart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Menu, Space } from "antd";
import logo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetLogedUserQuery,
  useLogoutMutation,
} from "../../redux/Service/api";
import {
  reduxLogin,
  reduxLogout,
  saveUserData,
} from "../../redux/Slices/authSlice";
import { language } from "../../language/Language";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, userData } = useSelector((st: any) => st.auth);

  const {
    data,
    isError: getUserError,
    isLoading,
    refetch,
  } = useGetLogedUserQuery(undefined);
  const [logout, { isLoading: logoutLoading, isError }] = useLogoutMutation();

  const handleLogoutClick = async () => {
    localStorage.removeItem("userToken");
    try {
      await logout(undefined);

      dispatch(reduxLogout());
      refetch();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  useEffect(() => {
    if (data && !getUserError) {
      dispatch(reduxLogin());
      dispatch(saveUserData(data?.data?.user));
    }
  }, [data, getUserError, dispatch]);

  const handleMenuClick = (key: string) => {
    if (key === "profile") {
      navigate(isAuthenticated ? "/ProfileUser" : "/login");
    } else if (key === "myOrder") {
      navigate("/MyOrder");
    } else if (key === "logout") {
      handleLogoutClick();
    }
  };

  const userMenu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="profile" className="flex items-center gap-4">
        <FontAwesomeIcon className="text-gray-600 text-xl" icon={faUser} />
        <span>{isAuthenticated ? data?.data.user.name : "Profile"}</span>
      </Menu.Item>
      <Menu.Item key="myOrder" className="flex items-center gap-3">
        <FontAwesomeIcon
          className="text-gray-600 text-xl"
          icon={faBabyCarriage}
        />
        <span>{language === "ar" ? "طلباتي" : "My Order"}</span>
      </Menu.Item>
      {isAuthenticated ? (
        <Menu.Item key="logout" className="flex items-center gap-3">
          <FontAwesomeIcon
            className="text-gray-600 text-xl"
            icon={faArrowRightToBracket}
          />
          <span>{language === "ar" ? "تسجيل خروج" : "Logout"}</span>
        </Menu.Item>
      ) : (
        <Menu.Item
          key="login"
          onClick={() => navigate("/login")}
          className="flex items-center gap-3"
        >
          <FontAwesomeIcon
            className="text-gray-600 text-xl"
            icon={faArrowRightToBracket}
          />
          <span>{language === "ar" ? "تسجيل الدخول" : "Login"}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <header className="container flex items-center justify-between py-4">
      <div>
        <Link to="/Cart">
          <FontAwesomeIcon
            className="text-3xl text-gray-800"
            icon={faCartShopping}
          />
        </Link>
      </div>
      <div>
        <Link to="/">
          <img className="w-36" src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="flex items-center gap-5">
        <Link to="/aboutus">About Us</Link>
        {userData?.role == "admin" && (
          <Link to="/dashboard">
            {language == "ar" ? "لوحة التحكم" : "Dashboard"}
          </Link>
        )}
        <Dropdown overlay={userMenu} trigger={["click"]}>
          <Space className="cursor-pointer">
            <FontAwesomeIcon className="text-3xl text-gray-800" icon={faUser} />
          </Space>
        </Dropdown>
        <Link to="/Favourite">
          <FontAwesomeIcon className="text-3xl text-gray-800" icon={faHeart} />
        </Link>
      </div>
    </header>
  );
};

export default Navbar;
